body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.w-full{
  width: 100% !important;
  /* max-width: unset !important; */
}

.w-3{
  width: 30px !important;
}
.h-3{
  height: 30px !important;
}

.w-10{
  width: 150px !important;
}
.h-10{
  height: 150px!important;
}

.w-unset{
 width: unset !important;
}

.bg-light{
  background-color: #F0FAF4 !important;
}
.h-unset{
  height: unset !important;;
}
.mx-w-10{
  max-width: 200px !important;
}

.min-width-unset{
  min-width: unset !important;
}

.edit-icon{
  transform: translate();
}
.zindex-1{
  z-index: 1;
}

.bg-unset{
  background: unset !important;
}

.border-bottom{
  border-bottom: 1px solid var(--color-border);
}

.bg-success{
  background-color: #34B140 !important;
}

.bg-success-light{
  background-color: #34B140 !important;
  color: #fff;
}
.border-success-light{
  background-color: #34B140 !important;
  color: #fff;
}

.btn-outline-success-light{
  border: 2px solid #34B140 !important;
  color: #2fa13b ;
}

.btn-outline-success-light:hover{
  background-color: #34B140!important;
  color: #fff;
}
.bg-success-light:hover{
  background-color: #2fa13b !important;
  color: #fff;
}
.form-check-input:checked{
  background-color: #2fa13b !important;
  border-color: #2fa13b !important;
  border: 1px solid #2fa13b  !important;
  box-shadow:  none !important;
}

.modal-header{
  position: relative;
  border-bottom: unset !important;
  padding: 10px;
  justify-content: center;
}
.modal-footer{
  border-top: unset!important;
  justify-content: center;
}
.modal-header .btn-close{
  box-sizing: border-box;
  padding: 7px !important;
  width: 30px !important;
  height: 30px !important;
  margin: 0px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 50%;
  transform: translate(30%,-30%);
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: rgb(54, 54, 54);
  opacity: 1 !important;
  padding: 10px;
  --bs-btn-close-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
}

.modal-header .btn-close .path{
  color: #fff !important;
  fill: #fff  !important;;
}



.-tl-0{
 transform: translate(-50%, -50%) !important;
 top:0 !important;
 right: -15px!important;
}

.bg-pink-light{
  background-color: #FBFAFA !important;
}

.card-options{
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  background: rgba(0,0,0,.5);
}

.card-options:hover{
  background-color: rgba(0,0,0,.2);
}