.content-slider {
   
    position: relative;
    padding: 0 15px;
 
  }

  .slide.slide-inactive {
    display: none;
}
  
  .slide {
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
   
    width: 100%;
    text-align: center;
  }
  
  .slide-active {
    opacity: 1;
  }
  
  .prev,
  .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .prev {
    left: 10px;
  }
  
  .next {
    right: 10px;
  }

  .modal-head {
    margin-bottom: 10px;
}

.prev,.next {
  left: -28px;
  background: #000;
  width: 35px;
  height: 35px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 27px;
  border-radius: 50% !important;
}
.next{ left: auto; right:-28px}

.modal-body {
  padding-top: 0;
}